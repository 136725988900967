<template>
    <div v-if="predioResult?.npn">
        <div style="margin-right:1.2rem; text-align:center;" class="mt-2" >
            <h4>Informacion del predio</h4>
            <table class="table table-striped table-hover table-bordered">
                <tbody>
                    <tr>
                        <th scope="row">Nnp</th>
                        <td>{{ predioResult?.npn }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Destino</th>
                        <td>{{ predioResult?.destino }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Area Total Terreno</th>
                        <td>{{ predioResult?.area_terreno_digitada }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Area Total Construida</th>
                        <td>{{ predioResult?.area_construida_digitada }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Direccion</th>
                        <td>{{ predioResult?.direccion }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="accordion d-grid gap-1 mt-1" id="accordionPanelsStayOpenExample" style="width:100%;">
            <div class="accordion-item mb-1">
            <h3 class="accordion-header">
                <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                TERRENOS
                </button>
            </h3>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" style='margin-top:0.2rem'>
                <div class="accordion-body" v-if="predioResult?.terrenos">
                    <table 
                        class="table table-striped table-hover table-bordered" 
                        v-for="(item, index) in predioResult?.terrenos"
                        :key="index"
                        >
                        <tbody>
                            <tr>
                                <th scope="row">Area digitada</th>
                                <td>{{item.area_digitada}}</td>
                            </tr>
                            <tr>
                                <th scope="row">Zona geoeconomica</th>
                                <td>{{ item.zhg }}</td>
                            </tr>
                            <tr>
                                <th scope="row">Zona fisica</th>
                                <td>{{ item.zhf }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    El predio no tiene terrenos asociados
                </div>
            </div>
            </div>
            <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    CONSTRUCCIONES
                </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse">
                <div class="accordion-body" v-if="predioResult?.unidades">
                    <table 
                        class="table table-striped table-hover table-bordered" 
                        v-for="(item, index) in predioResult?.unidades"
                        :key="index"
                        >
                        <tbody>
                            <tr>
                                <th scope="row">Identificador</th>
                                <td>{{item.identificador}}</td>
                            </tr>
                            <tr>
                                <th scope="row">Area construida</th>
                                <td>{{ item.area_construida }}</td>
                            </tr>
                            <tr>
                                <th scope="row">Uso</th>
                                <td>{{ item.uso }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="accordion-body">
                    El predio no tiene construcciones asociadas.
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap';

  import { mapState } from 'vuex'

  export default {
    name:'TreePanelResultado',
    computed:{
      ...mapState(['layers', 'predioResult'])
    },
  };
</script>


<style scoped>
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
    font-size:0.75rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }
  .accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text-emphasis);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
  }
  
</style>