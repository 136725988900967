<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_CARTOGRAFIA_BASICA;

export default {
    name:'Terrenos',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Municipios',
                layerNameGeoserver:'cartografia_basica:cartografia_municipio',
                paneName:'layerWmsMunicipios',
                minZoom: 0,
                maxZoom: 16,
                zIndex:'400',
                categoria:'diviPoli',
                role:['Consulta','Admin','Vallevanza']
            }
        }
    }
}
</script>