<template>
    <div style="width:100" class="m-2">
        <p class="t-6 fs-6 fw-bold">Consulta por tabla de atributos</p>
        <select 
            class="form-select mb-1" 
            aria-label="Default select example" 
            @change="getMunicipios" 
            v-model="departamento"
        >
            <option
                v-for="item in departamentos" 
                :key="item.id"
                :value='item.id'
            >{{item.nombre}}</option>
        </select>
        <v-spacer></v-spacer>
        <select class="form-select mb-1" aria-label="Default select example" v-model="municipio" :disabled='municipios.length < 1'>
            <option 
                v-for="item in municipios" 
                :key="item.id"
                :value='item.codigo'
            >{{item.nombre}}</option>
        </select>
        <v-btn
            color="green darken-1"
            class="mb-4"
            block
            @click="getPredios"
            :disabled="departamento === ''" 
        >
            Consultar
        </v-btn>
        <DialogTable 
            :dialogTable = 'dialog'
            :prediosTable = 'predios'
            :disabledButtonTableChild='disabledButtonTable'
            :url='urlDepValle' 
            @closeDialogChild='changeStateDialog' 
        />
    </div>
</template>
 
<script>
import DialogTable from '@/components/map/sidebar/tabla/DialogTable.vue';
import axios from '@/services/axios/axios'

export default {
    name:'Tabla',
    components:{
        DialogTable
    },
    data() {
        return {
            dialog:false,
            disabledButtonTable:true,
            json:[],
            predios:[],
            departamentos:[],
            departamento:'',
            municipios:[],
            municipio:'',
            urlDepValle:'',
        }
    },
    mounted() {
        this.getDepartamentos()
    },
    methods:{
        changeStateDialog(data){
            this.dialog = data
        },
        capitalize(str){
            if (str && typeof str === 'string') {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }
            return str;
        },
        async getDepartamentos() {
            try {
                const response = await axios.get('cartografia/list_departamentos');
                this.json = response.data.results;
                this.departamentos = response.data.results.map(item => item);
            } catch (error) {
                console.error('Error al obtener los departamentos:', error);
                // Manejo de errores adicionales, si es necesario
            }
        },
        getMunicipios(){
            // Buscar el objeto del departamento seleccionado
            if (!this.departamentos || this.departamentos.length === 0) {
                return;
            }
            // Filtrar y aplanar la lista de municipios basado en el departamento seleccionado
            this.municipios = this.departamentos.flatMap(departamento => 
                departamento.municipios.filter(municipio => 
                    municipio.depa === this.departamento
                )
            );
        },
        async getInfoFetch(departamento, municipio = null) {
            let url = `geovisor/list_predio/${departamento}`;
            if (municipio) {
                url += `/${municipio}`;
            }
            this.urlDepValle = url;
            try {
                const response = await axios.get(url);
                return response.data;
            } catch (error) {
                console.error('Error al obtener información:', error);
                // Manejar el error adecuadamente aquí
            }
        },
        async getPredios() {
            try {
                const predios = await this.getInfoFetch(this.departamento, this.municipio);
                this.dialog = true;
                this.disabledButtonTable = false;
                this.predios = predios;
                // Otras operaciones con predios
            } catch (error) {
                console.error('Error al obtener los predios:', error);
                // Manejar el error aquí
            }
        }
    },

    }
</script>