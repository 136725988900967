<template>
    <transition name="fade">
    <div 
        class="search-npn-panel" 
        :class="{ 'minimized': panelMinimizado }" 
        id="draggable-panel"
        @mousedown="dragStart"
        :style="{ top: position.y + 'px', left: position.x + 'px' }"
        >
        
        <v-card style="height:100%">
            <v-card-title class="bg-primary" style="height:1.5em; font-size:1.7em; padding:0px; text-align:center;">
                <span>
                    Consulte el predio
                </span>
                <!-- Botón para minimizar el panel, utilizando un icono de 'X' -->
                <v-icon style="float:right" class="clickable-icon" @click="togglePanel">{{ panelMinimizado ? 'mdi-window-maximize' : 'mdi-minus' }}</v-icon>
            </v-card-title>

            <v-card-text>
            <div class="text-caption pa-2"></div>

            <v-text-field
                v-model="valueNpn"
                density="compact"
                :disabled='loading'
                hint="Digita el npn de 30 posiciones"
                label="NPN"
                persistent-hint
                prepend-icon="mdi-city"
            >
                <template v-slot:append>
                    <v-slide-x-reverse-transition mode="out-in">
                        <v-btn
                            :loading = 'loading'
                            variant="flat"
                            :disabled='loading'
                            @click="enviarButton(valueNpn)"
                            size="x-small"
                            style="font-size:20px;margin:0"
                        >
                            <v-icon
                                color="primary"
                                icon="mdi-magnify"
                                >
                            </v-icon>
                        </v-btn>
                    </v-slide-x-reverse-transition>
                </template>
            </v-text-field>
            </v-card-text>
        </v-card>
        <SnackBar :open='open' :propSnackbar='propSnackbar'  @update:open="open = $event" />
    </div>
    </transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SnackBar from '@/components/common/SnackBar.vue'
import axios from '@/services/axios/axios';

// TOAST
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    name:'SearchButton',
    components:{
        SnackBar,
    },
    data() {
        return {
            valueNpn:'761470102000000270045000000000',
            panelMinimizado: false,
            open:false,
            propSnackbar:{
                text:'Consulta exitosa',
                color:'success'
            },
            loading:false,
            states:[],
            rules: {
                required: value => !!value || 'Field is required',
                counter: value => value.length <= 20 || 'Max 20 characters',
            },
            dragging: false,
            position: {
                x: 20,
                y: 20
            },
            offset: {
                x: 0,
                y: 0
            }
        }
    },
    computed:{
        ...mapState(['controls'])
    },
    methods: {
        ...mapMutations(['addPredioResult']),
        validaciones(cadena){
            // LA EXPRESION REGULAR EVALUA SI LA CADENNA TIENE 20 0 30 POSICIONES Y QUE TODOS SEAN NUMERICOS
            let regex = /^\d{20}(\d{10})?$/;
            return regex.test(cadena);
        },
        togglePanel() {
            // Alterna el estado de minimización del panel
            this.panelMinimizado = !this.panelMinimizado;
        },
        ...mapActions(['addLayerAsync']),
        async enviarButton(npn){
            const controlSidebar = this.controls.find((control) => control.nameControl === 'sidebar')
            if (this.validaciones(npn)) {
                this.loading = true
                const diccionario = {
                    parametro:npn,
                    nameLayer:'consulta',
                    style:() => {
                        return {
                            color: 'orange',  // Color del contorno
                            weight: 2,        // Grosor del contorno
                            opacity: 1,       // Opacidad del contorno
                            fillColor: 'transparent', // Color del relleno
                            fillOpacity: 0    // Opacidad del relleno
                        }
                    }
                }
                const parametro = diccionario.parametro;
                try {
                    const response = await axios.get(`geovisor/geojson/${parametro}`);
                    const json = response.data;
                    const predio = json.predios[0];
                    // ADICIONAMOS EL RESULTADO D
                    this.addPredioResult(predio);
                    const terreno = json.predios[0]?.terreno_geo;
                    diccionario.data = terreno;
                    this.addLayerAsync(diccionario);
                    controlSidebar.control.open('result');
                    toast.success(json.message)
                } catch (error) {
                    this.propSnackbar.text = 'Error al enviar la informacion'
                    toast.error(this.propSnackbar.text)
                }finally {
                    this.loading = false;
                }
           
            }else{
                this.propSnackbar.text = 'El npn debe ser de 30 digitos y solo debe contener caracteres numericos'
                toast.info("El npn debe ser de 30 digitos y solo debe contener caracteres numericos.")
            }
        },
        dragStart(event) {
            this.dragging = true;
            this.offset.x = event.clientX - this.position.x;
            this.offset.y = event.clientY - this.position.y;
            window.addEventListener('mousemove', this.draggingHandler);
            window.addEventListener('mouseup', this.dragStop);
        },
        draggingHandler(event) {
            if (this.dragging) {
                this.position.x = event.clientX - this.offset.x;
                this.position.y = event.clientY - this.offset.y;
            }
        },
        dragStop() {
            this.dragging = false;
            window.removeEventListener('mousemove', this.draggingHandler);
            window.removeEventListener('mouseup', this.dragStop);
        }
    },
}
</script>

<style scoped>
    .search-npn-panel {
        position: fixed;
        top: 0;
        right: 0;
        cursor: move;
        /* Add any other styles you need */
    }
    .clickable-icon {
        cursor: pointer; /* Esto asegura que el cursor sea una mano al pasar sobre el ícono */
    }
    .search-npn-panel{
        z-index: 400;
        position: absolute;
        top: 1em;
        right: 4em;
        width: 25%;
        height: 13em;
        font-size:10px;
    }

    .v-card-text{
        padding: 0.2rem;
    }

    /* Estilos para cuando el panel esté minimizado */
    .minimized {
        height: 1.6rem; /* O la altura mínima que quieras para el panel minimizado */
        overflow: hidden; /* Para evitar que el contenido desborde */
    }
    /* Podrías querer que el título del panel siga visible */
    .search-npn-panel.minimized .v-card-title {
        position: absolute;
        width: 100%;
        top: 0;
    }

    @media (max-width: 1000px) {
        #card-search-predio {
            width: 50%;
        }
        .search-npn-panel{
            width: 43.5%;
        }
    }

    @media (min-width: 400px) and (max-width: 600px) {
        .search-npn-panel{
            width: 70%;
        }
    }


</style>