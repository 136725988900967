<template>
  <div id="containerMap">
    <div id="map"></div>
    <SearchButton />
    <ZoomExtent />
    <InfoClick />
    <Info /> 
    <Municipios />
    <Terrenos />
    <Construcciones />
    <Zonas_Fisicas/>
    <Zonas_Geoeconomicas/>
    <Cartago />
    <Guacari />
    <Sidebar />
    <!-- <Malambo /> -->
    <Dagua11 />
    <Dagua12 />
    <Dagua13 />
    <Dagua14 />
    <Dagua21 />
    <Dagua22 />
    <Dagua23 />
    <Dagua24 />
    <Dagua31 />
    <Dagua32 />
    <Dagua33 />
    <Dagua34 />
    <Lacumbre22/>
    <Lacumbre23/>
    <Lacumbre32/>
    <Lacumbre33/>
    <Lacumbre34/>
    
  </div>
</template>

<script>
    // STORE
    import { mapState, mapMutations } from 'vuex';
    
    //*************custom library
    // LEAFLET
    import '@/assets/plugins/leaflet_1.9.4/leaflet.js';
    import '@/assets/plugins/leaflet_1.9.4/leaflet.css';
    
    // SIDEBAR
    import '@/assets/plugins/sidebar_v2/leaflet-sidebar.min.js';

    // PROJ4
    import '@/assets/plugins/proj4js-2.11.0/proj4.js';

    //MEASURE
    import '@/assets/plugins/leaflet-geoman/leaflet-geoman.js';
    import '@/assets/plugins/leaflet-geoman/leaflet-geoman.css';
    import '@/assets/plugins/leaflet-measure-path-master/leaflet-measure-path.js';
    import '@/assets/plugins/leaflet-measure-path-master/leaflet-measure-path.css';


    // CUSTOM COMPONENT
    import Sidebar from '@/components/map/sidebar/Sidebar.vue';
    import SearchButton from '@/components/searchButton/SearchButton.vue';

    import ZoomExtent from '@/components/map/buttons/ZoomExtent.vue'
    import InfoClick from '@/components/map/buttons/InfoClick.vue'
    import Info from '@/components/map/buttons/Info.vue'
    
    // LOAD LAYER 
    import Municipios from '@/components/map/layers/Municipios.vue';
    import Terrenos from '@/components/map/layers/Terrenos.vue';
    import Construcciones from '@/components/map/layers/Construcciones.vue';
    import Cartago from '@/components/map/layers/ortofotos/Cartago.vue';
    import Guacari from '@/components/map/layers/ortofotos/Guacari.vue';
    import Dagua11 from '@/components/map/layers/ortofotos/Dagua11.vue';
    import Dagua12 from '@/components/map/layers/ortofotos/Dagua12.vue';
    import Dagua13 from '@/components/map/layers/ortofotos/Dagua13.vue';
    import Dagua14 from '@/components/map/layers/ortofotos/Dagua14.vue';
    import Dagua21 from '@/components/map/layers/ortofotos/Dagua21.vue';
    import Dagua22 from '@/components/map/layers/ortofotos/Dagua22.vue';
    import Dagua23 from '@/components/map/layers/ortofotos/Dagua23.vue';
    import Dagua24 from '@/components/map/layers/ortofotos/Dagua24.vue';
    import Dagua31 from '@/components/map/layers/ortofotos/Dagua31.vue';
    import Dagua32 from '@/components/map/layers/ortofotos/Dagua32.vue';
    import Dagua33 from '@/components/map/layers/ortofotos/Dagua33.vue';
    import Dagua34 from '@/components/map/layers/ortofotos/Dagua34.vue';
    import Malambo from '@/components/map/layers/ortofotos/Malambo.vue';
    import Lacumbre22 from '@/components/map/layers/ortofotos/Lacumbre22.vue';
    import Lacumbre23 from '@/components/map/layers/ortofotos/Lacumbre23.vue';
    import Lacumbre32 from '@/components/map/layers/ortofotos/Lacumbre32.vue';
    import Lacumbre33 from '@/components/map/layers/ortofotos/Lacumbre33.vue';
    import Lacumbre34 from '@/components/map/layers/ortofotos/Lacumbre34.vue';
    import Zonas_Geoeconomicas from './layers/Zonas_Geoeconomicas.vue';
    import Zonas_Fisicas from './layers/Zonas_Fisicas.vue'
    //import Terrenos_centroid from './layers/Terrenos_centroid.vue';

    export default {
      name: 'Map',
      data() {
        return {
          layerData:[]
        }
      },
      components:{
        Sidebar,
        ZoomExtent,
        SearchButton,
        Municipios,
        Terrenos,
        //Terrenos_centroid,
        Construcciones,
        Zonas_Fisicas,
        Zonas_Geoeconomicas,
        Cartago,
        Guacari,
        InfoClick,
        Info,
        Dagua11,
        Dagua12,
        Dagua13,
        Dagua14,
        Dagua21,
        Dagua22,
        Dagua23,
        Dagua24,
        Dagua31,
        Dagua32,
        Dagua33,
        Dagua34,
        Lacumbre22,
        Lacumbre23,
        Lacumbre32,
        Lacumbre33,
        Lacumbre34,
        Malambo
      },
      computed:{
        ...mapState(['map', 'layers']),
      },
      methods: {
        ...mapMutations(['updateMap']), // Importa la mutación
        updateMapInStore(newMap) {
          this.updateMap(newMap); // Llama a la mutación con el nuevo valor
        },
      },
      watch: {
        // Observador para 'map'
        layers(newLayer) {
            if (newLayer) {
                this.layerData=newLayer
            }
        }
      },
      mounted() {
        
        var base_layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });

        var satelite_layer = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>'
        });
        
        // Crea el mapa y establece las coordenadas iniciales y el zoom
        
        const map = L.map('map',{
          center: [4.2, -76.8],
          zoom: 9,
          maxZoom:23,
          zoomControl: false,
          layers:[base_layer,satelite_layer]
        })
        this.updateMapInStore(map);

        // Agregar un nuevo control de zoom en la esquina inferior izquierda
        new L.Control.Zoom({ position: 'bottomleft' }).addTo(map);

        var baseMaps = {
          "Mapa satelital": satelite_layer,
          "Mapa base":base_layer
        }

        L.control.layers(baseMaps).addTo(map);


       
        
        // Añadir herramientas de dibujo y edición de Leaflet-Geoman
        map.pm.addControls({
          position: 'bottomright',
          drawMarker: false,
          drawPolygon: true,
          drawPolyline: true,
          drawCircle: true,
          drawCircleMarker: true,
          drawRectangle: true,
          editMode: true,
          dragMode: true,
          cutPolygon: true,
          removalMode: true
        });

        //modificación de estilos
        this.map.pm.setPathOptions({
                color: 'orange',
                fillColor: 'green',
                fillOpacity: 0.4,
        });

        // Manejar eventos de Leaflet-Geoman

     
        // Añadir Leaflet Measure Path
        const measureOptions = {
          showDistances: true,
          showArea: true,
          showOnHover: true,
          minPixelDistance: 30,
          showTotalDistance: true,
          lang: {
            totalLength: 'Total length',
            totalArea: 'Total area',
            segmentLength: 'Segment length'
          }
        };

        map.on('pm:create', ({ layer }) => {
          if (layer instanceof L.Polyline || layer instanceof L.Polygon) {
            layer.showMeasurements(measureOptions);
          }
        });

        // Integrar Leaflet Measure Path con Leaflet-Geoman
        map.on('pm:drawstart', ({ workingLayer }) => {
          if (workingLayer instanceof L.Polyline || workingLayer instanceof L.Polygon) {
            workingLayer.on('pm:vertexadded', (e) => {
              workingLayer.updateMeasurements();
            });
          }
        });


      },
    };
</script>

<style scoped>
  #containerMap {
    height: 100%;
  }
  #map {
    height: 100%;
    width: 100%;
  }
  

</style>