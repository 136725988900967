<template>
    <v-menu v-if="verifyUser">
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
          @click="disabledListMenu"
        >
            Descarga
        </v-btn>
      </template>
      <v-list
        :disabled='!selected'
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :value="index"
        >   
          <v-list-item-title @click="() => item.action(seleccionRows)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <SnackBar :open='open' :propSnackbar='propSnackbar'/>
    </v-menu>
</template>

<script>
    import axios from '@/services/axios/axios';
    import { authService } from '@/services/axios/authService';
    import SnackBar from '@/components/common/SnackBar.vue';

    export default {
        name:'MenuDescarga',
        props:['seleccionRows'],
        components:{
          SnackBar
        },
        data: () => ({
            selected:false,
            open:false,
            propSnackbar:{
              text:'Consulta exitosa',
              color:'success'
            },
        }),
        created() {
            this.items = [
                { title: '.shp', action: this.downloadFeature },
                { title: '.csv', action: this.downloadCSV },
                { title: '.xlsx', action: this.downloadXLSX },
            ];
        },
        watch:{
            seleccionRows(newVal){
                this.selected = newVal.length > 0;
            },
        },
        computed: {
          verifyUser() {
            return authService.userHasRole(['Descarga','Admin']);
          }
        },
        methods: {
          disabledListMenu(){
              this.selected = this.seleccionRows.length > 0
          },
          async downloadFeature(predio){
              const list_npn = predio.map((item)=> item.npn)
              const npnString = list_npn.join(',');
              const url = `geovisor/download?npn=${encodeURIComponent(npnString)}`;
              try {
                const response = await axios.get(`${url}`);
                window.location.href = response.data.url_file_zip;
              } catch (error) {
                this.propSnackbar.text = 'Descarga no permitida'
              } finally {
                this.open = true
              }
          },
          async downloadCSV(predio){
              console.log(predio)
              // const npn = this.seleccionRows?.npn
              // try {
              //     const response = await axios.get(`download/${npn}`);
              // } catch (error) {
              //     console.error('Error al cargar datos:', error);
              // } finally {
              //     this.loading = false;
              // }

          },
          async downloadXLSX(predio){
              console.log(predio)
              // const npn = this.seleccionRows?.npn
              // try {
              //     const response = await axios.get(`download/${npn}`);
              // } catch (error) {
              //     console.error('Error al cargar datos:', error);
              // } finally {
              //     this.loading = false;
              // }

          },

        },
    }
</script>