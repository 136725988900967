<template>
    <div class="ml-1 w-100">
        <a @click="logout" class="float-end" style="cursor: pointer;">
            Cerrar sesion
        </a>
        <v-list-item
            lines="two"
            :title="username"
            subtitle="Logueado"
            class="w-100 p-0"

         >
            <template v-slot:prepend>
                <input type="file" @change="onFileSelected" hidden ref="fileInput" accept=".jpg, .jpeg">
                <v-avatar size="80" @click="triggerFileInput">
                    <img v-if="imageUrl" :src="imageUrl" alt="Avatar" width="60" height="60">
                    <v-img v-else :src="urlIconUser"></v-img>
                </v-avatar>
            </template>
         </v-list-item>
        <v-divider></v-divider>
        <p>
            El GEOVISOR CATASTRAL es una herramienta interactiva de mapeo y análisis que proporciona acceso en tiempo real a información catastral detallada y georreferenciada. Diseñado con tecnología de vanguardia, nuestro geovisor ofrece una experiencia de usuario intuitiva y eficiente, facilitando la gestión de la tierra y la propiedad tanto para profesionales como para particulares.
        </p>
        <h4>Funciones Principales:</h4>
        <p>
            Herramientas de Búsqueda Avanzada: Localiza rápidamente propiedades y terrenos utilizando filtros personalizados y búsquedas por criterios específicos.
        </p>
        <p>
            Análisis Espacial: Evalúa el uso del suelo, planifica el desarrollo urbano y analiza cambios geográficos con herramientas de análisis incorporadas.
        </p>
        <p>
            Visualización de Datos Multicapa: Accede a diversas capas de información, desde registros históricos hasta proyecciones urbanísticas futuras.
        </p>
    </div>
</template>

<script>
    import axios from '@/services/axios/axios';
    import { authService } from '@/services/axios/authService';
    import { useRouter } from 'vue-router';

    import User from '@/assets/customIcons/user.png'

    // TOAST
    import { useToast } from 'vue-toastification'
    const toast = useToast()

    export default {
        name: 'Home',
        data() {
            return {
                imageUrl: null, // URL de la imagen seleccionada
                urlIconUser:User,
                username:''
            }
        },
        mounted() {
            const user = JSON.parse(localStorage.getItem('user'));
            this.username = user.username;
            this.getAvatar();
           
        },
        methods: {
            async getAvatar(){
                const response = await authService.getAvatar()
                if (response){
                    this.imageUrl = response.data.avatar_url
                }
            },
            triggerFileInput() {
                this.$refs.fileInput.click();
            },
            async uploadImage(file) {
                const formData = new FormData();
                formData.append('avatar', file);

                try {
                    const response = await axios.post('user/update-avatar/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    // Actualizar la interfaz de usuario o realizar otras acciones necesarias
                    this.imageUrl = response.data.avatar_url; // Ajusta según tu API
                } catch (error) {
                    toast.info("Error al subir la imagen.")
                }
            },
            onFileSelected(event) {
                const file = event.target.files[0];
                if (!file) {
                    toast.info("No hay un archivo seleccionado.")
                    return;
                }

                // Validar que el archivo sea una imagen JPG
                if (!file.type.startsWith('image/jpeg')) {
                    toast.info("El archivo seleccionado no es una imagen.")
                    return;
                }
                this.imageUrl = URL.createObjectURL(file);
                this.uploadImage(file); // Llama a la función para subir la imagen
            },
            async logout() {
                try {
                    await authService.logout();
                    this.router.push('/login');
                    window.location.reload();   // Recargar la página
                } catch (error) {
                    console.error('Error durante el cierre de sesión:', error);
                    // Manejar el error, como mostrar un mensaje al usuario
                }
            },
        },
        created() {
            this.router = useRouter();
        }
    }
</script>

<style scoped>
    .icono-personalizado img{
        width: 2em; /* Ancho igual al tamaño de letra */
        height: 2em; /* Alto igual al tamaño de letra */
    }
    
</style>