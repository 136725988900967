<template>
</template>

<script>
import { mapMutations, mapState} from 'vuex';
import { authService } from '@/services/axios/authService';

export default {
    name:'LayerWMS',
    props:['item'],
    data() {
        return {
            roles: []
        }
    },
    computed:{
        ...mapState(['map'])
    },
    methods: {
        ...mapMutations(['addLayer']),
        verificarRole(){
            this.roles = authService.getUser()?.roles
        },
        initializeLayer(map, item) {
            // Aquí pones la lógica para inicializar tu capa WMS
            const url_workspace_valleavanza = item.url;
            map.createPane(item.paneName);
            map.getPane(item.paneName).style.zIndex = item.zIndex;
            const layerWmsTerrenos = new L.TileLayer.WMS(url_workspace_valleavanza, {
                layers: item.layerNameGeoserver,
                pane: item.paneName,
                format: 'image/png8',
                transparent: true,
                opacity: 0.8,
                minZoom: item.minZoom,
                maxZoom: item.maxZoom,
                version: '1.3.0',
            });
            const array_fotos = ['Cartago','Guacari','Malambo','Dagua11','Dagua12','Dagua13','Dagua14','Dagua21','Dagua22','Dagua23','Dagua24','Dagua31','Dagua32','Dagua33','Dagua34', 'Malambo','Lacumbre22','Lacumbre23','Lacumbre32','Lacumbre33','Lacumbre34']
            if (!array_fotos.includes(item.nameLayer)){
                layerWmsTerrenos.addTo(map);
            }
            
            const layer={
                nombre: item.nameLayer,
                layer : layerWmsTerrenos,
                categoria: item.categoria,
                role: item.role,
            }

            // this.roles.forEach(element => {
            //     Object.keys(item.role).forEach(clave => {
            //         if(element === item.role[clave]){
            //             console.log(element,'ssss')
            //             console.log(item.role[clave],'xxxx')
            //             this.addLayer(layer)
            //         }
            //     });
            // });
            this.addLayer(layer)
            
        }
    },
    watch: {
        // Observador para 'map'
        map(newMap) {
            if (newMap) {
                this.initializeLayer(newMap, this.item);
            }
        }
    },
    mounted() {
        this.verificarRole()
        if (this.map) {
            // Si 'map' ya está definido en el momento en que se monta el componente
            this.initializeLayer(this.map, this.item);
        }
    },
}
</script>