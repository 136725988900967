<template>
    <!-- optionally define the sidebar content via HTML markup -->
    <div id="sidebar" class="leaflet-sidebar collapsed">
        <!-- Nav tabs -->
        <div class="leaflet-sidebar-tabs">
            <ul role="tablist"> <!-- top aligned tabs -->
                <li><a href="#home" role="tab"><i class="fa fa-bars"></i></a></li>
                <!-- <li><a href="#convenciones" role="tab"><img :src="iconoUrlReport" alt="User" class="icono-personalizado"></a></li> -->
                <li><a href="#layers" role="tab"><img :src="iconoUrlLayer" alt="User" class="icono-personalizado"></a></li>
                <li ><a href="#result" role="tab"><img :src="iconoUrlAnalysis" alt="User" class="icono-personalizado"></a></li>
                <li ><a href="#changeNpn" role="tab"><img :src="iconoUrlExchange" alt="User" class="icono-personalizado"></a></li>
                <li ><a href="#locationCoord" role="tab"><img :src="iconoUrlLocation" alt="User" class="icono-personalizado"></a></li>
                <li ><a href="#table" role="tab"><img :src="iconoUrlTable" alt="User" class="icono-personalizado"></a></li>
                <li ><a href="#upload" role="tab"><img :src="iconoUrlUpload" alt="User" class="icono-personalizado"></a></li>
            </ul>

            <ul role="tablist"> <!-- bottom aligned tabs -->
                <li><a href="#settings" role="tab"><img :src="iconoUrlGear" alt="User" class="icono-personalizado"></a></li>
            </ul>
        </div>

        <!-- Tab panes -->
        <div class="leaflet-sidebar-content">
            <div class="leaflet-sidebar-pane" id="home">
                <h5 class="leaflet-sidebar-header">
                    <span>GEOVISOR CATASTRAL</span>
                    <div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div>
                </h5>
                <Home />
            </div>

            <!-- <div class="leaflet-sidebar-pane" id="convenciones">
                <h1 class="leaflet-sidebar-header">Convenciones<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                Aqui iran las convenciones
            </div> -->

            <div class="leaflet-sidebar-pane" id="layers">
                <h1 class="leaflet-sidebar-header">CAPAS<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <TreePanel />
            </div>
            <div class="leaflet-sidebar-pane" id="result">
                <h1 class="leaflet-sidebar-header">Resultados<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <TreePanelResultado />
            </div>
            <div class="leaflet-sidebar-pane" id="changeNpn">
                <h1 class="leaflet-sidebar-header">Tranformacion<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <ExchangeNpn />
            </div>
            <div class="leaflet-sidebar-pane" id="locationCoord">
                <h1 class="leaflet-sidebar-header">Localizacion<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <LocationCoord />
            </div>
            <div class="leaflet-sidebar-pane" id="table">
                <h1 class="leaflet-sidebar-header">Tabla<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <Tabla />
            </div>
            <div class="leaflet-sidebar-pane" id="upload">
                <h1 class="leaflet-sidebar-header">Upload<div class="leaflet-sidebar-close"><i class="fa fa-caret-left"></i></div></h1>
                <UploadFile />
            </div>
        </div>
    </div>
</template>

<script>
    // SIDEBAR
    import '@/assets/plugins/sidebar_v2/leaflet-sidebar.min.js';
    import iconLayers from '@/assets/customIcons/layers.png'; // Asegúrate de que la ruta sea correcta
    import iconReport from '@/assets/customIcons/convenciones.png'; // Asegúrate de que la ruta sea correcta
    import iconGears from '@/assets/customIcons/gears.png'; // Asegúrate de que la ruta sea correcta
    import iconAnalysis from '@/assets/customIcons/analysis.png'; // Asegúrate de que la ruta sea correcta
    import iconExchange from '@/assets/customIcons/exchange.png'; // Asegúrate de que la ruta sea correcta
    import iconLocation from '@/assets/customIcons/map-pin.png'; // Asegúrate de que la ruta sea correcta
    import iconTable from '@/assets/customIcons/stats.png'; // Asegúrate de que la ruta sea correcta
    import iconUpload from '@/assets/customIcons/upload.png'; // Asegúrate de que la ruta sea correcta

    import { mapState, mapMutations } from 'vuex';

    import TreePanel from '@/components/map/sidebar/TreePanel.vue';
    import TreePanelResultado from '@/components/map/sidebar/TreePanelResultado.vue';
    import ExchangeNpn from '@/components/map/sidebar/ExchangeNpn.vue';
    import LocationCoord from '@/components/map/sidebar/LocationCoord.vue';
    import Tabla from '@/components/map/sidebar/tabla/Tabla.vue';
    import Home from '@/components/map/sidebar/home/Home.vue';
    import UploadFile from '@/components/map/sidebar/uploadFile/UploadFile.vue';

    export default {
        name: 'Sidebar',
        components:{
            TreePanel,
            TreePanelResultado,
            ExchangeNpn,
            LocationCoord,
            Tabla,
            Home,
            UploadFile
        },
        data: () => ({
            panel: [0, 1],
            readonly: false,
            iconoUrlLayer: iconLayers,
            iconoUrlTable:iconTable,
            iconoUrlReport: iconReport,
            iconoUrlGear: iconGears,
            iconoUrlAnalysis: iconAnalysis,
            iconoUrlExchange: iconExchange,
            iconoUrlLocation:iconLocation,
            iconoUrlUpload:iconUpload,
        }),
        computed:{
            ...mapState(['map'])
        },
        watch: {
            // Observador para 'map'
            map(newMap) {
                if (newMap) {
                    this.initializeControl(newMap);
                }
            }
        },
        methods: {
            ...mapMutations(['addControl']),
            initializeControl(map){
                const sidebar = L.control.sidebar({
                    autopan: false,       // whether to maintain the centered map point when opening the sidebar
                    closeButton: true,    // whether t add a close button to the panes
                    container: 'sidebar', // the DOM container or #ID of a predefined sidebar container that should be used
                    position: 'right',     // left or right
                });
                sidebar.addTo(map)
                sidebar.open('home')
                const diccionario = {
                    nameControl: 'sidebar',
                    control: sidebar
                };
                this.addControl(diccionario);
            },
        },
        mounted() {
            if (this.map){
                this.initializeControl(this.map)
            };
        },

    }
</script>

<style scoped>
    .leaflet-sidebar-pane {
        display: none;
        left: 0;
        right: 0;
        box-sizing: border-box;
        padding: 10px 10px 10px 10px;
    }
    .icono-personalizado {
        width: 1.5em; /* Ancho igual al tamaño de letra */
        height: 1.5em; /* Alto igual al tamaño de letra */
    }
</style>