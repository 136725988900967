<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackBar"
      :color="propSnackbar.color"
    >
      {{ propSnackbar.text }}

      <template v-slot:actions>
        <v-btn
          color="red"
          @click="snackBar = false"
        >
            <v-icon
                color="red"
                icon="mdi mdi-close"
                >
            </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name:'SnackBar',
    props:['open', 'propSnackbar'],
    data() {
      return {
        snackBar:false
      }
    },
    watch: {
        open(newVal) {
          this.snackBar = newVal;
        },
        snackBar(newVal) {
          if (newVal === false) {
            this.$emit('update:open', newVal);
          }
        }
    },
    mounted() {
        this.snackBar = this.open
    },
  }
</script>