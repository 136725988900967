<template>
  <v-app>
    <!-- <Map />
    <Login /> -->
    <router-view></router-view>
  </v-app>
</template>

<script>
  
  import Map from '@/components/map/Map.vue';
  import Login from '@/components/login/Login.vue'

  export default {
    name: 'App',
    components: {
      Map,
      Login
    }
  }
</script>

<style>
  @import url('~@/assets/plugins/leaflet_1.9.4/leaflet.css');
  @import url('~@/assets/plugins/sidebar_v2/leaflet-sidebar.min.css');
  @import url('~@/assets/plugins/leaflet_dialog/Leaflet.Dialog.css');
</style>
