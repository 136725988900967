<template>
  <div class="accordion d-grid gap-1 mt-1" id="accordionPanelsStayOpenExample" style="width:100%;">
    <div class="accordion-item mb-1">
      <h3 class="accordion-header">
        <button class="accordion-button  fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
          CATASTRO
        </button>
      </h3>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" style='margin-top:0.2rem'>
        <div class="accordion-body">
          <!-- LAYER DIVIPOLI -->
          <div v-for="(item, index) in layersLocal" :key="index">
            <AccordionLayer :item='item' v-if="item.categoria==='catastral'" />
          </div>
          <!-- LAYER TERRENOS -->
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
          DIVISION POLITICA
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse">
        <div class="accordion-body">
          <!-- LAYER DIVIPOLI -->
          <div v-for="(item, index) in layersLocal" :key="index">
            <AccordionLayer :item='item' v-if="item.categoria==='diviPoli'" />
          </div>
          <!-- LAYER TERRENOS -->
        </div>
      </div>
    </div>
    <div class="accordion-item" v-if="rolesOrtofotos">
      <h2 class="accordion-header">
        <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
          ORTOFOTOS
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse">
        <div class="accordion-body">
          <!-- LAYER DIVIPOLI -->
          <div v-for="(item, index) in layersLocal" :key="index">
            <AccordionLayer :item='item' v-if="item.categoria==='ortofotos'" />
          </div>
          <!-- LAYER TERRENOS -->
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button  fw-bold"  type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          CONSULTA
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
        <div class="accordion-body">
          <!-- LAYER CONSULTA -->
          <div v-for="(item, index) in layersLocal" :key="index">
            <AccordionLayer :item='item' v-if="item.categoria==='consulta'" />
          </div>
          <!-- LAYER CONSULTA -->
        </div>
      </div>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
        <div class="accordion-body">
          <!-- LAYER CONSULTA -->
          <div v-for="(item, index) in layersLocal" :key="index">
            <AccordionLayer :item='item' v-if="item.categoria==='baseMaps'" />
          </div>
          <!-- LAYER CONSULTA -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import AccordionLayer from '@/components/map/sidebar/AccordionLayer.vue'
  import { authService } from '@/services/axios/authService';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap';

  import { mapState } from 'vuex'

  export default {
    name:'TreePanel',
    components:{
      AccordionLayer
    },
    computed:{
      ...mapState(['layers'])
    },
    data() {
      return {
        role: false,
        layersLocal: this.layers,
        rolesOrtofotos:false
      }
    },
    methods: {
      verificarRole(){
            const roles = authService.getUser()?.roles
            roles.forEach(element => {
              if (element === 'Cartago' || element === 'Guacari' || element === 'Admin' || element === 'Valleavanza' || element === 'Dagua' || element === 'Malambo' || element === 'Lacumbre'){
                this.rolesOrtofotos = true
              }
            });
            
      },
    },
    mounted() {
      this.layersLocal = this.layers
      this.verificarRole()
    },
  };
</script>


<style scoped>
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
    font-size:0.75rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }
  .accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text-emphasis);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
  }
  
</style>