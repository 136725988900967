<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_GUACARI;

console.log(url_ortofoto,'guacari')
export default {
    name:'Terrenos',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Guacari',
                layerNameGeoserver:'ortofotos_guacari:ortofoto_guacari',
                paneName:'layerOrtofotoGuacari',
                minZoom: 16,
                maxZoom: 23,
                zIndex:'200',
                categoria:'ortofotos',
                role:['Guacari','Admin','Vallevanza']
            }
        }
    }
}
</script>