<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_CARTOGRAFIA;

console.log(url_ortofoto,'zonasf')

export default {
    name:'Zonas_Fisicas',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Zonas_Fisicas',
                layerNameGeoserver:'catastral:geovisor_zonas_fisicas',
                paneName:'layerWmsZonasFisicas',
                minZoom: 16,
                maxZoom: 27,
                zIndex:'201',
                categoria:'catastral',
                role:['Admin','Vallevanza']
            }
        }
    }
}
</script>