<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_CARTOGRAFIA;

export default {
    name:'Zonas_Geoeconomicas',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Zonas_Geoeconomicas',
                layerNameGeoserver:'catastral:geovisor_zonas_geoeconomicas',
                paneName:'layerWmsZonasGeoeconomicas',
                minZoom: 16,
                maxZoom: 27,
                zIndex:'201',
                categoria:'catastral',
                role:['Admin','Vallevanza']
            }
        }
    }
}
</script>