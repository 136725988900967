<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_DAGUA;
//console.log(url_ortofoto,'dagua21')
export default {
    name:'Dagua21',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Dagua21',
                layerNameGeoserver:'ortofotos_dagua:ortofoto_dagua_21',
                paneName:'layerOrtofotoDagua21',
                minZoom: 16,
                maxZoom: 23,
                zIndex:'200',
                categoria:'ortofotos',
                role: ['Dagua','Admin','Vallevanza']
            }
        }
    }
}
</script>