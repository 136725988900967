<template>
    
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    
    // PROJ4
    import proj4 from '@/assets/plugins/proj4js-2.11.0/proj4.js';

    export default {
        name: 'Info',
        data() {
            return {
                controlInfo:undefined,
                firstProjection:'+proj=tmerc +lat_0=4.0 +lon_0=-73.0 +k=0.9992 +x_0=5000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
            }
        },
        computed:{
            ...mapState(['map'])
        },
        watch: {
            // Observador para 'map'
            map(newMap) {
                if (newMap) {
                    this.initializeControl(newMap);
                    this.hoverMapCoordinates(newMap)
                }
            }
        },
        methods: {
            ...mapMutations(['addControl']),
            convertirCoordenadas(arrayCoordinate){
                return proj4(this.firstProjection, arrayCoordinate)
            },
            hoverMapCoordinates(map){
                const self = this
                map.on({
                    mousemove:function(e){
                        self.controlInfo.update(e.latlng);
                    },
                })
            },
            initializeControl(map){
                this.controlInfo = L.control();
                
                const convertirCoordenadas = (arrayCoordinate)=>{
                    return proj4(this.firstProjection, arrayCoordinate)
                }
                
                this.controlInfo.onAdd = function (map) {
                    this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
                    this.update();
                    return this._div;
                };
                // method that we will use to update the control based on feature properties passed
                this.controlInfo.update = function (props) {
                    if (props && props.lng !== undefined && props.lat !== undefined) {
                        const arrayCoordinates = [props.lng, props.lat];
                        const coordenadasConvertidas = convertirCoordenadas(arrayCoordinates);
                        this._div.innerHTML = `
                            <h6><strong>Origen nacional</strong></h6>
                            <h6><strong>EPSG : 9377</strong></h6>
                            ${coordenadasConvertidas[0].toFixed(2)}, ${coordenadasConvertidas[1].toFixed(2)}
                        `;
                    } else {
                        this._div.innerHTML = 'Coordenadas no disponibles';
                    }
                };

                this.controlInfo.addTo(map);
                const diccionario = {
                    nameControl: 'info',
                    control: this.controlInfo
                };
                this.addControl(diccionario);
            }
        },
    }
</script>

<style>
    .info {
        padding: 6px 8px;
        font: 14px/16px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255,255,255,0.8);
        box-shadow: 0 0 15px rgba(0,0,0,0.2);
        border-radius: 5px;
    }
    .info h4 {
        margin: 0 0 5px;
        color: #777;
    }
</style>