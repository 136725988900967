<template>
  <div class="text-center">
    <v-btn
      color="primary"
      block
      :disabled= "disabledButtonTableChild"
  >
      Tabla de atributos
      <v-dialog
        v-model="dialog"
        activator="parent"
        width="auto"
      >
        <v-card>
          <v-card-text>
            <DataTable :prediosTableChild='predios' :urlDepMun='url' />
          </v-card-text>
          <v-card-actions>
            <v-btn 
              color="primary" 
              block 
              @click="$emit('closeDialogChild', false)" 
            >Cerrar ventana</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-btn>
  </div>
</template>

<script>
import DataTable from '@/components/map/sidebar/tabla/DataTable.vue'
export default {
    name:'DialogTable',
    props:['dialogTable', 'disabledButtonTableChild', 'prediosTable','url'],
    components:{
      DataTable
    },
    data () {
      return {
          dialog: this.dialogTable,
          predios: this.prediosTable
      }
    },
    watch: {
      dialogTable(newVal) {
        this.dialog = newVal;
      },
      prediosTable(newVal) {
        this.predios = newVal;
      },
    },
}
</script>