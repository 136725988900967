import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(store).use(router).use(vuetify).use(Toast, {
  // Setting the global default position
  position: POSITION.BOTTOM_CENTER
}).mount('#app')
