<template>
    <div id="app">
        <div class="login-page">
            <transition name="fade">
                <div class="wallpaper-login"></div>
            </transition>
            <div class="wallpaper-register"></div>

            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
                        <div v-if="registerActive === 1" class="card login" v-bind:class="{ error: emptyFields }">
                            <div class="d-flex mb-4">
                                <img :src="gobernacionUrl" alt="" height="55" width="180">
                                <img :src="vallevanzaUrl" alt="" height="40" width="130" class="ms-auto">
                            </div>
                            <v-form v-if='!enviarLogin'  @submit.prevent="doLogin" v-model='formLogin'>
                                <v-text-field
                                    v-model="emailLogin"
                                    :rules="emailRules"
                                    variant="underlined"
                                    type="email"
                                    label="Email"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-text-field
                                    v-model="passwordLogin"
                                    :rules="passwordRules"
                                    :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showLogin ? 'text' : 'password'"
                                    variant="underlined"
                                    label="Password"
                                    @click:append="showLogin = !showLogin"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-btn 
                                    class="text-none mb-6"
                                    color="success"
                                    rounded="0"
                                    type='submit'
                                    block
                                    elevated
                                >
                                Iniciar sesión</v-btn>
                                <p class="custom-text fw-bold">¿No tienes cuenta? 
                                    <a href="#" @click="registerActive = 3, emptyFields = false">Crea una gratis aqui</a>
                                </p>
                                <p class="custom-text fw-bold"><a href="#" @click="registerActive = 2, emptyFields = false">¿Olvidaste la contraseña?</a></p>
                            </v-form>
                            
                            <v-btn
                                class="text-none ms-4 text-white"
                                color="blue-darken-4"
                                rounded="0"
                                variant="flat"
                                @click="showModal = true">
                                Video Tutorial
                            </v-btn>
                                                       
                            <div v-if="showModal">                                
                                <VideoTutorial ref="modalComponent" :showModal='showModal' @close="cerrarModalVideo"></VideoTutorial>
                            </div>
                        </div>
                        

                        <div v-else-if="registerActive === 2" class="card recovery" v-bind:class="{ error: emptyFields }">
                            <div class="d-flex mb-4">
                                <img :src="gobernacionUrl" alt="" height="55" width="180">
                                <img :src="vallevanzaUrl" alt="" height="40" width="130" class="ms-auto">
                            </div>
                            <h4 class="mb-4 text-center">Recuperar contraseña</h4>
                            <v-form v-if='!enviarResetPass'  @submit.prevent="doRecover" v-model='formRecovery'>
                                <v-text-field
                                    v-model="emailRecovery"
                                    :rules="emailRules"
                                    variant="underlined"
                                    type="email"
                                    label="Email"
                                    class="mb-3"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-btn 
                                    class="text-none mb-6"
                                    color="success"
                                    rounded="0"
                                    type='submit'
                                    block
                                    elevated
                                >Enviar informacion</v-btn>
                                <p class="custom-text fw-bold">¿Recordaste tu cuenta? <a href="#" @click="registerActive = 1">Iniciar sesión aquí</a></p>
                            </v-form>
                            <div v-else class="d-flex justify-content-around">
                                <img :src="iconoUrlCheck" alt="" class="me-auto p-2" width="60" height="60">
                                <p class="custom-text ml-1 p-2 text-justify"> Se ha enviado un mensaje a la dirección de correo electrónico registrada para recuperación de la contraseña <a href="#" @click="registerActive = 1; enviarResetPass = false">Iniciar sesión aqui.</a></p>
                            </div>
                        </div>

                        <div v-else class="card register" >
                            <div class="d-flex mb-4">
                                <img :src="gobernacionUrl" alt="" height="55" width="180">
                                <img :src="vallevanzaUrl" alt="" height="40" width="130" class="ms-auto">
                            </div>
                            <h3 v-if='!enviarRegisterUser' class="text-center">Registro</h3>
                            <h3 v-else class="text-center">Registro exitoso</h3>
                            <v-form v-if='!enviarRegisterUser' @submit.prevent="doRegister" v-model='form'>
                                <!-- Campos con validación -->
                                <v-text-field
                                    v-model="username"
                                    :rules="verificarUsername"
                                    @input="(e) => verificarUsuario(e)"
                                    variant="underlined"
                                    label="Username"
                                ></v-text-field>
                                <v-text-field
                                    v-model="emailReg"
                                    :rules="emailRules"
                                    variant="underlined"
                                    type="email"
                                    label="Email"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-text-field
                                    v-model="confirmEmailReg"
                                    :rules="confirmEmailRegRules"
                                    variant="underlined"
                                    type="email"
                                    label="Confirmar Email"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-text-field
                                    v-model="firstname"
                                    variant="underlined"
                                    label="Nombres"
                                ></v-text-field>
                                <v-text-field
                                    v-model="lastname"
                                    variant="underlined"
                                    label="Apellidos"
                                ></v-text-field>
                                <v-text-field
                                    v-model="passwordReg"
                                    :rules="passwordRules"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                    variant="underlined"
                                    label="Password"
                                    @click:append="show1 = !show1"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <v-text-field
                                    v-model="confirmReg"
                                    :rules="confirmPasswordRules"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show2 ? 'text' : 'password'"
                                    variant="underlined"
                                    label="Confirmar Password"
                                    @click:append="show2 = !show2"
                                    @copy.prevent
                                    @paste.prevent
                                ></v-text-field>
                                <!-- Botón de registro -->
                                <!-- <span class="text-center fst-italic text-danger" style="font-size:0.7rem">sdsds</span> -->
                                <v-btn
                                    class="text-none mt-2"
                                    color="success"
                                    rounded="0"
                                    type='submit'
                                    block
                                    elevated
                                >Registrarse</v-btn>
                                <p class="custom-text mt-2 fw-bold">¿Recordaste tu cuenta? <a href="#" @click="registerActive = 1">Iniciar sesión aquí</a></p>
                            </v-form>
                            <div v-else class="d-flex justify-content-around">
                                <img :src="iconoUrlCheck" alt="" class="me-auto p-2" width="60" height="60">
                                <p class="custom-text ml-1 p-2 text-justify"> Se ha enviado un mensaje a la dirección de correo electrónico registrada para la validación de su cuenta <a href="#" @click="registerActive = 1">Iniciar sesión aqui</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SnackBar :open='open' :propSnackbar='propSnackbar'  @update:open="open = $event" />
    </div>
</template>

<script>

import axios from 'axios';
import SnackBar from '@/components/common/SnackBar.vue';
import { authService } from '@/services/axios/authService';
import { useRouter } from 'vue-router';


//ICONS
import checkmark from '@/assets/customIcons/check-mark.png'; // Asegúrate de que la ruta sea correcta
import valleavanzaIco from '@/assets/customIcons/valleavanza.png'; // Asegúrate de que la ruta sea correcta

//IMG
import gobernacion from '@/assets/img/gobernacion.png';
import valleavanza from '@/assets/img/valleavanza.png';

// TOAST
import { useToast } from 'vue-toastification'
const toast = useToast()

//VIDEO
import VideoTutorial from '@/components/login/VideoTutorial.vue';


export default {
    name:'Login',
    components:{
        SnackBar,
        VideoTutorial //integrar el componente modal
    },
    data() {
        return {
            //video
            showModal:false, 

            // icon
            iconoUrlCheck: checkmark,
            iconoUrlValleavanza: valleavanzaIco,
            //IMG
            gobernacionUrl: gobernacion,
            vallevanzaUrl: valleavanza,
            //SNACKBAR
            open:false,
            propSnackbar:{
                text:'Consulta exitosa',
                color:'success'
            },
            //FORM REGISTER
            form:false,
            show1: false,
            show2: false,
            registerActive: 1,
            emailReg: "",
            confirmEmailReg:"",
            passwordReg: "",
            confirmReg: "",
            username:"",
            usernameVerfified:false,
            firstname:"",
            lastname:"",
            usernameRules:[
                v => !!v || 'El nombre de usuario es requerido',
            ],
            emailRules: [
                v => !!v || 'Email es requerido',
                v => /.+@.+\..+/.test(v) || 'Email debe ser válido'
            ],
            verificarUsername:[
                v => !!v || 'El nombre de usuario es requerido',
                v => !this.usernameVerfified.status || 'El nombre de usuario ya existe'
            ],
            confirmEmailRegRules: [
                v => !!v || 'Email es requerido',
                v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
                v => v === this.emailReg || 'Los emails deben coincidir'
            ],
            passwordRules: [
                v => !!v || 'Contraseña es requerida',
                // v => v.length >= 8 || 'Contraseña debe tener al menos 8 caracteres',
                // v => /[!@#$%^&*(),.?":{}|<>]/.test(v) || 'Contraseña debe tener al menos un carácter especial @#$%&'
            ],
            confirmPasswordRules: [
                v => !!v || 'Confirmar contraseña es requerido',
                v => v === this.passwordReg || 'Las contraseñas deben coincidir'
            ],
            emptyFields: false,
            passwordRecoveryActive: false,
            enviarLogin:false,
            enviarResetPass:false,
            enviarRegisterUser:false,
            // FORM RECOVERY
            emailRecovery:'',
            formRecovery:'',
            //FORM LOGIN
            formLogin:false,
            showLogin:false,
            emailLogin: "",
            passwordLogin: "",

        }
    },
    mounted() {
        this.router = useRouter();
    },
    methods: {
        async verificarUsuario(e){
            const formData = new FormData();
            formData.append('username', e.target.value);
            const url = `user/verificar_usuario/`
            try {
                this.usernameVerfified = await this.enviarDatos(formData, url, false);
            } catch (error) {
                console.error("Error al verificar usuario:", error);
            }
        },
        async enviarDatos (formData, url, snack = true) {
            
            try {
                const response = await axios.post(url, formData);
                return response.data; // Retorna los datos para uso posterior si es necesario
            } catch (error) {
                // Si el servidor responde con un mensaje de error, lo obtienes así:
                if (error.response && error.response.data) {
                    // Aquí puedes acceder a los datos de la respuesta de error
                    if (snack){
                        this.propSnackbar.text = error.response.data.message;
                        this.propSnackbar.color = 'warning';
                        this.open = true;
                    }

                } else {
                    // Error en la solicitud, pero sin respuesta del servidor
                    this.propSnackbar.text = `
                        El servidor no responde a tiempo. 
                        Esto puede deberse a una alta demanda. 
                        Inténtalo de nuevo más tarde.`;
                    this.propSnackbar.color = 'error';
                    this.open = true;
                }
            }
        },
        async doLogin() {
            if (!this.formLogin) return
            try {
                const userData = new FormData();
                userData.append('email', this.emailLogin);
                userData.append('password', this.passwordLogin);
                await authService.login(userData);
                this.$router.push('/map');
            }catch (error) {
                if (error.code === 'ERR_NETWORK'){
                    toast.error("En este momento el servidor se encuentra ocupado. Intenta mas tarde.");
                }else{
                    if (error.response.data.detail){
                        toast.error("Usuario y contraseña incorrecto.");
                    }
                }
            }
        },
        async doRecover() {
            if (!this.formRecovery) return;
            const response = await authService.resetPassword(this.emailRecovery);
            if (response == true){
                this.enviarResetPass = true
                this.emailRecovery=''
                toast.success('Se enviado un correo a la direccion suministrada.')
            }else if (response.code === 'ERR_NETWORK'){
                toast.error('En este momento el servidor se encuentra ocupado. Intenta mas tarde.');
            }else{
                toast.warning('Hay un problema con email suministrado. Por favor comuniquese al correo info@valleavanza.com');
            }
            
            
        },
        async doRegister() {
            if (!this.form) return;
            try {
                const userData = new FormData();
                userData.append('email', this.emailReg);
                userData.append('password', this.passwordReg);
                userData.append('username', this.username);
                userData.append('first_name', this.firstname);
                userData.append('last_name', this.lastname);
                await authService.register(userData);
                this.emailReg = '',
                this.passwordReg = '',
                this.username = '',
                this.firstname = '',
                this.lastname = '',
                this.confirmEmailReg = '',
                this.confirmReg = '',
                this.enviarRegisterUser = true;
            }catch (error) {
                if (error.code === 'ERR_NETWORK'){
                    this.propSnackbar.text = "En este momento el servidor se encuentra ocupado. Intenta mas tarde.";
                    this.propSnackbar.color = 'error';
                    this.open = true;
                }else{
                    if (error.response.data.email){
                        this.propSnackbar.text = error.response.data.email[0];
                        this.propSnackbar.color = 'error';
                        this.open = true;
                    }
                }
            }
        },
        cerrarModalVideo(nuevoValor) {
            this.showModal = nuevoValor;
        },
        cerrarModal() {
            const modalComponent = this.$refs.modalComponent;
            if (modalComponent && modalComponent.$refs.videoPlayer) {
                const videoPlayer = modalComponent.$refs.videoPlayer;
                if (!videoPlayer.paused) {
                videoPlayer.pause();
                }
            }
        }

    },

}
</script>

<style scoped>
    .card {
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.9); /* Blanco con opacidad 50% */
        /* Resto de tus estilos... */
    }
    .card * {
        /* Establecer la opacidad de todos los elementos dentro del card a 1 (completamente opacos) */
        opacity: 1;
    }
    .custom-text{
        font-size: 0.8rem;
    }
    p {
        line-height: 1rem;
    }

    .card {
        padding: 20px;
    }

    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
    }

    .wallpaper-login {
        background: url('@/assets/img/background_login.jpg')
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    
    .wallpaper-register {
        background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    h1 {
        margin-bottom: 1.5rem;
    }
  

    .error {
        animation-name: errorShake;
        animation-duration: 0.3s;
    }

    @keyframes errorShake {
        0% {
            transform: translateX(-25px);
        }
        25% {
            transform: translateX(25px);
        }
        50% {
            transform: translateX(-25px);
        }
        75% {
            transform: translateX(25px);
        }
        100% {
            transform: translateX(0);
        }
    }
   
</style>