<template>
    <div>
        <transition name="fade">
            <div ></div>
        </transition>
        <div class="wallpaper-register"></div>
        <div class="container">
            <div class="row">
                <div v-if='isTokenValid' class="col-lg-4 col-md-6 col-sm-8 mx-auto">
                    <div class="card login" v-bind:class="{ error: emptyFields }">
                        <h3>Reestablecer contraseña</h3>
                        <v-form v-if='!enviarConfirmPass' @submit.prevent="resetPassword" v-model='formRecovery'>
                            <v-text-field
                                v-model="passwordReg"
                                :rules="passwordRules"
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass ? 'text' : 'password'"
                                variant="underlined"
                                label="Password"
                                @click:append="showPass = !showPass"
                                @copy.prevent
                                @paste.prevent
                            ></v-text-field>
                            <v-text-field
                                v-model="confirmReg"
                                :rules="confirmPasswordRules"
                                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showConfirmPass ? 'text' : 'password'"
                                variant="underlined"
                                label="Confirmar Password"
                                @click:append="showConfirmPass = !showConfirmPass"
                                @copy.prevent
                                @paste.prevent
                            ></v-text-field>
                            <v-btn 
                                class="text-none mb-6"
                                color="success"
                                prepend-icon="$vuetify"
                                rounded="0"
                                type='submit'
                                block
                                elevated
                            >Cambiar contraseña</v-btn>
                        </v-form>
                        <div v-else class="d-flex justify-content-around">
                            <img :src="iconoUrlCheck" alt="" class="me-auto p-2" width="60" height="60">
                            <p class="custom-text ml-1 p-2 text-justify"> Se ha reestablecido la contraseña con exito. Por favor vaya a loguearse. <a href="/login">Logueate aqui.</a></p>
                        </div>
                    </div>
                </div>
                <div v-else class="col-lg-4 col-md-6 col-sm-8 mx-auto">
                    <div class="card login" v-bind:class="{ error: emptyFields }">
                        <h3>Reestablecer contraseña</h3>
                        <div class="d-flex justify-content-around">
                            <!-- <img :src="iconoUrlCheck" alt="" class="me-auto p-2" width="60" height="60"> -->
                            <p class="custom-text ml-1 p-2 text-justify"> En enlace ha expirado <a href="/login" @click="registerActive = 1; ">Logueate aqui.</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { authService } from '@/services/axios/authService';
    //ICONS
    import checkmark from '@/assets/customIcons/check-mark.png'; // Asegúrate de que la ruta sea correcta

    export default {
        data() {
            return {
                // icon
                isTokenValid: false,  // Agregar una propiedad para controlar la validez del token
                tokenExpirationTimer: null,
                iconoUrlCheck: checkmark,
                showPass: false,
                showConfirmPass: false,
                emptyFields: false,
                formRecovery:false,
                passwordReg: "",
                confirmReg: "",
                passwordRules: [
                    v => !!v || 'Contraseña es requerida',
                    v => v.length >= 8 || 'Contraseña debe tener al menos 8 caracteres',
                    v => /[!@#$%^&*(),.?":{}|<>]/.test(v) || 'Contraseña debe tener al menos un carácter especial @#$%&'
                ],
                confirmPasswordRules: [
                    v => !!v || 'Confirmar contraseña es requerido',
                    v => v === this.passwordReg || 'Las contraseñas deben coincidir'
                ],
                uidb64: '',
                token:'',
                enviarConfirmPass:false
            };
        },
        mounted() {
            this.uidb64 = this.$route.params.uidb64;
            this.token = this.$route.params.token;
            this.verifyToken(this.uidb64, this.token);  // Verificar el token cuando el componente se monta
        },
        methods: {
            async resetPassword(){
                if (!this.formRecovery) return

                try {
                    const response = await authService.resetPasswordConfirm(this.uidb64, this.token, this.passwordReg);
                    if (response && response.data) {
                        // Si la respuesta es exitosa
                        this.enviarConfirmPass = true;  // Cambiar a true solo si la respuesta es exitosa
                        return response.data;
                    }else {
                        // Manejar caso en que la respuesta no contenga los datos esperados
                        this.enviarConfirmPass = false;
                        // Opcional: Establecer enviarConfirmPass a false o manejar de otra manera
                    }
                } catch (error) {
                    if (error.response && error.response.data) {
                        // Aquí manejas los errores específicos de la respuesta
                        this.propSnackbar.text = error.response.data.message || 'Error al restablecer la contraseña.';
                    } else {
                        // Error en la solicitud, pero sin respuesta del servidor
                        this.propSnackbar.text = `
                            El servidor no responde a tiempo. 
                            Esto puede deberse a una alta demanda. 
                            Inténtalo de nuevo más tarde.`;
                    }
                    this.enviarConfirmPass = false;
                }
            },
            async verifyToken(uidb64, token) {
                const esTokenValido  = await authService.validarToken(uidb64, token);
                this.isTokenValid = esTokenValido;
                if (this.isTokenValid) {
                    this.setTokenExpirationTimer(); // Establecer el temporizador si el token es válido
                } else {
                    this.$router.push('/login'); // Redirigir si el token no es válido
                }
            },
            setTokenExpirationTimer() {
                const expirationDuration = 2 * 60 * 1000;  // Duración hasta la expiración del token (por ejemplo, 10 minutos)
                this.tokenExpirationTimer = setTimeout(() => {
                    this.verifyTokenAgain();
                }, expirationDuration);
            },
            async verifyTokenAgain() {
                const esTokenValido  = await authService.validarToken(this.uidb64, this.token);
                if (!esTokenValido) return  this.$router.push('/login');
            },
        },
        beforeDestroy() {
            if (this.tokenExpirationTimer) {
                clearTimeout(this.tokenExpirationTimer);  // Limpiar el temporizador si el componente se destruye
            }
            this.passwordReg= "";
            this.confirmReg= "";
        }
    };
</script>

<style scoped>
    .custom-text{
        font-size: 0.8rem;
    }
    p {
        line-height: 1rem;
    }

    .card {
        padding: 20px;
    }

    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
    }

    .wallpaper-login {
        background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    
    .wallpaper-register {
        background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    h1 {
        margin-bottom: 1.5rem;
    }
  

    .error {
        animation-name: errorShake;
        animation-duration: 0.3s;
    }

    @keyframes errorShake {
        0% {
            transform: translateX(-25px);
        }
        25% {
            transform: translateX(25px);
        }
        50% {
            transform: translateX(-25px);
        }
        75% {
            transform: translateX(25px);
        }
        100% {
            transform: translateX(0);
        }
    }
</style>