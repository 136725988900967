<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_LACUMBRE;

export default {
    name:'Lacumbre32',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Lacumbre32',
                layerNameGeoserver:'ortofotos_Lacumbre:ortofoto_cumbre_32',
                paneName:'layerOrtofotoLacumbre32',
                minZoom: 16,
                maxZoom: 32,
                zIndex:'200',
                categoria:'ortofotos',
                role: ['Lacumbre','Admin','Vallevanza']
            }
        }
    }
}
</script>