<template>
       <SnackBar :open='open' :propSnackbar='propSnackbar'  @update:open="open = $event" />
</template>

<script>
    import SnackBar from '@/components/common/SnackBar.vue';
    import '@/assets/plugins/leaflet_EasyButton/easy-button.js';
    import axios from '@/services/axios/axios'

    import IconInfo from '@/assets/customIcons/info.png';

    import { mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'InfoClick',
        components:{
            SnackBar
        },
        data() {
            return {
                open:false,
                propSnackbar:{
                    text:'Consulta exitosa',
                    color:'success'
                },
                iconUrl:IconInfo,
                isMapClickable:false
            }
        },
        computed:{
            ...mapState(['map','controls'])
        },
        watch: {
            // Observador para 'map'
            map(newMap) {
                if (newMap) {
                    this.initializeControl(newMap);
                }
            }
        },
        methods: {
            ...mapActions(['addLayerAsync']),
            ...mapMutations(['addControl','addPredioResult']),
            toggleMapClickable() {
                this.isMapClickable = !this.isMapClickable;
                if (this.isMapClickable) {
                    this.map.on('click', this.handleMapClick);
                } else {
                    this.map.off('click', this.handleMapClick);
                }
            },
            async handleMapClick(e) {
                if (!this.isMapClickable) return;
                const controlSidebar = this.controls.find((control) => control.nameControl === 'sidebar')
                const diccionario = {
                    parametro: '',
                    nameLayer: 'consulta',
                    style: () => {
                        return {
                            color: '#800080',  // Color del contorno
                            weight: 2,        // Grosor del contorno
                            opacity: 1,       // Opacidad del contorno
                            fillColor: 'transparent', // Color del relleno
                            fillOpacity: 0    // Opacidad del relleno
                        }
                    }
                };
                
                const lat = e.latlng?.lat;
                const lng = e.latlng?.lng;
                
                try {
                    const response = await axios.get(`geovisor/intercept/geojson/${lat}/${lng}`);
                    const json = response.data;
                    const predio = json.predios[0];
                    this.addPredioResult(predio);
                    const terreno = json.predios[0]?.terreno_geo;
                    if (terreno){
                        diccionario.data = terreno;
                        this.addLayerAsync(diccionario);
                    };
                    controlSidebar.control.open('result')
                } catch (error) {
                    this.propSnackbar.text = error.response.data.message;
                    this.propSnackbar.color = 'warning';
                    this.open = true;
                }
            },
            initializeControl(map){
                const self = this;
                const clickInfo = L.easyButton({
                    position: 'bottomleft',
                    states: [{
                        stateName: 'info-click',        // name the state
                        icon:      `<img src="${this.iconUrl}" style="width: 1.8em; height: 1.8em;">`,               // and define its properties
                        title:     'Informacion de predios',      // like its title
                        onClick: function(btn, map) {       // and its callback
                            var buttonElement = btn.button;
                            // Alternar la clase para cambiar el estilo
                            // Alternar la clase para cambiar el estilo
                            if (buttonElement.classList.contains('info-click-button-pressed')) {
                                buttonElement.classList.remove('info-click-button-pressed');
                            } else {
                                buttonElement.classList.add('info-click-button-pressed');
                            };
                            self.toggleMapClickable(map);
                        }
                    }]
                });
                clickInfo.addTo(map);
                const diccionario = {
                    nameControl: 'zoomExtent',
                    control: clickInfo
                };
                this.addControl(diccionario);
            }
        },
    }
</script>

<style>
    @import url('~@/assets/plugins/leaflet_EasyButton/easy-button.css');
    .info-click-button-pressed {
        background-color: #535252; /* Color de fondo gris más intenso */
        border: 1px solid #8c8c8c; /* Borde ligeramente más oscuro para definición */
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3); /* Sombra interior para efecto de hundimiento */
        color: #333; /* Color del texto, en caso de que sea necesario */
        transform: translateY(1px); /* Desplazamiento ligero para simular estar presionado */
    }
  
</style>