<template>
    <v-dialog
        transition="dialog-top-transition"
        width="30%"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            class="float-end"
            variant="text"
            v-bind="props"
          ><img  :src="IconUrlInformation" width="30" height="30"> <!-- Aquí es donde usas el icono como un componente v-icon --></v-btn>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card>
            <v-toolbar color="bg-grey" title="Informacion">
                <img :src="IconUrlInformation" width="30" height="30" alt="Información" class="mr-5">
                <!-- El resto del contenido del toolbar -->
            </v-toolbar>
            <v-card-text>
              <div class="text-justify custom-text">
                Este modulo esta diseñado para que los usuarios suban archivos Shapefile comprimidos en un archivo ZIP. 
                El Shapefile es un formato popular de datos geoespaciales que contiene geometrías y atributos geográficos 
                para la representación de entidades espaciales como puntos, líneas y polígonos. Al requerir que los archivos estén en el sistema de 
                referencia de coordenadas EPSG 4326, se garantiza la compatibilidad con el sistema de coordenadas geográficas más utilizado, 
                que define la longitud y latitud en el globo terrestre basándose en el sistema WGS 84.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="isActive.value = false"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>
</template>

<script>
    import Information from '@/assets/customIcons/information.png';
    export default {
        name:'DialogInfo',
        data() {
            return {
                IconUrlInformation:Information,
            }
        },
    }
</script>

<style scoped>
    .custom-text{
        font-size: 0.75em;
    }
</style>