<template>
  <v-card
    class="mx-auto mt-2"
    variant="elevated"
  >
    <template v-slot:title>
        <img  :src="IconUrlShp" width="30" height="30"> <!-- Aquí es donde usas el icono como un componente v-icon -->
        Cargar Shapefile
        <DialogInfo class="float-end" />
      <v-divider></v-divider>
    </template>

    <v-card-text>
        <v-form 
            @submit.prevent="uploadShp" 
            v-model='formUploadShp'
        >
            <v-file-input
                :rules="rules"
                v-model="fileShape"
                label="Selecciona tu archivo"
                prepend-icon="mdi-paperclip"
                variant="underlined"
                density="compact"
                show-size
                counter
                accept=".zip"
                :disabled='loading'
            ></v-file-input>
            <v-btn
                v-if="formUploadShp"
                class="text-none mt-2"
                color="success"
                rounded="0"
                type='submit'
                block
                elevated
                :loading='loading'
            >Subir Shapefile</v-btn>
        </v-form>
    </v-card-text>
    
  </v-card>
</template>

<script>
    import Shp from '@/assets/customIcons/shp.png';
    
    import axios from '@/services/axios/axios';

    import DialogInfo from './DialogInfo.vue'

    import { mapActions } from 'vuex';
    // TOAST
    import { useToast } from 'vue-toastification'
    const toast = useToast()

    export default {
        name:'UploadFile',
        components:{
            DialogInfo
        },
        data() {
            return {
                loading:false,
                // V MODEL FORM SHAPE
                fileShape:null,
                IconUrlShp:Shp,
                
                formUploadShp:false,
                rules: [
                    value => !!value || 'Debes seleccionar un archivo.',
                    value => !value || !value.length || value[0].size < 10000000 || 'El archivo debe ser menor a 10 MB!',
                    value => !value || !value.length || /\.zip$/i.test(value[0].name) || 'El archivo debe ser un .zip',
                ],
                
            }
        },
        methods: {
            ...mapActions(['addLayerAsync']),
            async uploadShp() {
                
                if (!this.formUploadShp) return;
                this.loading = true
                const formData = new FormData();
                const file = this.fileShape
                formData.append('file', file[0]);
                try {
                    const response = await axios.post('/geovisor/upload/shapefile/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    const diccionario = {
                        nameLayer:'upload',
                        data : response.data,
                        style:() => {
                            return {
                                color: 'red',  // Color del contorno
                                weight: 2,        // Grosor del contorno
                                opacity: 1,       // Opacidad del contorno
                                fillColor: 'transparent', // Color del relleno
                                fillOpacity: 0    // Opacidad del relleno
                            }
                        }
                    };
                    this.addLayerAsync(diccionario);
                    toast.success('Archivo cargado con exito');
                    this.fileShape = null;
                } catch (error) {
                    if (error.code === 'ERR_NETWORK') {
                        toast.error('En este momento el servidor se encuentra ocupado. Intenta más tarde.');
                    } else if (error.response) {
                        if (error.response.data) {
                            toast.info(error.response.data[0]); // Asumiendo que el mensaje de error está en data[0]
                        } 
                    } else {
                        // Error no relacionado con la respuesta HTTP (podría ser un problema de programación)
                        toast.error('Ocurrió un error inesperado.');
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
    }
</script>

<style scoped>
    
</style>