<template>
    <LayerWMS :item='confLayer' />
</template>

<script>
import LayerWMS from '@/components/common/LayerWMS.vue';
const url_ortofoto = process.env.VUE_APP_WORKSPACE_DAGUA;

export default {
    name:'Dagua32',
    components:{
        LayerWMS
    },
    data() {
        return {
            confLayer:{
                url: url_ortofoto,
                nameLayer:'Dagua32',
                layerNameGeoserver:'ortofotos_dagua:ortofoto_dagua_32',
                paneName:'layerOrtofotoDagua32',
                minZoom: 16,
                maxZoom: 23,
                zIndex:'200',
                categoria:'ortofotos',
                role: ['Dagua','Admin','Vallevanza']
            }
        }
    }
}
</script>