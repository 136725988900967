import axios from './axios';

export const authService = {
    async login(userData) {
        const response = await axios.post(`/api/token/`, userData);
        // Aquí puedes manejar la respuesta del servidor, si es necesario
        localStorage.setItem('user', JSON.stringify(response.data)); // Almacenar la información del usuario
        return response.data;
    },
    async register(userData) {
        const response = await axios.post(`/user/register/`, userData);
        return response.data
    },
    async logout() {
        // Limpia los datos del usuario en Local Storage
        localStorage.removeItem('user');
        // Llama a la API para cerrar la sesión y eliminar las cookies
        return axios.post(`/api/logout/`);
    },
    async resetPassword(email) {
        try {
            const requestBody = {
                email: email,
            };
            await axios.post('/user/password-reset/', requestBody);
            return true;
        } catch (error) {
            return error;
        }
    },
    async validarToken(uidb64, token) {
        try {
            const requestBody = {
                token: token,
                uidb64:uidb64
            };
            await axios.post('/user/validar-token/', requestBody);
            return true; // Asegúrate de que tu backend maneje y responda apropiadamente
        } catch (error) {
            return false;
        }
    },
    async resetPasswordConfirm(uidb64, token, newPassword) {
        try {
            const requestBody = {
                uidb64: uidb64,
                token: token,
                password: newPassword
            };
            const response = await axios.post('/user/password-reset-confirm/', requestBody);
            return response; // Asegúrate de que tu backend maneje y responda apropiadamente
        } catch (error) {
            return false;
        }
    },
    async activateAccount(uidb64, token) {
        try {
            const requestBody = {
                uidb64: uidb64,
                token: token
            };
            const response = await axios.post('/user/activate/', requestBody);
            return 'ok'; // Asegúrate de que tu backend maneje y responda apropiadamente
        } catch (error) {
            return error;
        }
    },
    async checkAuth() {
        try {
            const response = await axios.get('/api/auth/verify/');
            return response.data.isAuthenticated; // El endpoint debe devolver el estado de autenticación
        } catch (error) {
            return false;
        }
    },
    async getAvatar() {
        try {
            const response = await axios.get('/user/obtener-avatar/');
            return response; // El endpoint debe devolver el estado de autenticación
        } catch (error) {
            return false;
        }
    },
    userHasRole(listRole=undefined) {
        const user = JSON.parse(localStorage.getItem('user'));
        return user && user.roles && user.roles.some(elemento => listRole.includes(elemento));
    },
    getUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user;
    }
    // Otras funciones relacionadas con la autenticación...
};