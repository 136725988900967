<template>
       
</template>

<script>
    import '@/assets/plugins/leaflet_EasyButton/easy-button.js';
    import IconZoomExtent from '@/assets/customIcons/earth.png';

    import { mapState, mapMutations } from 'vuex';
    export default {
        name: 'ZoomExtent',
        data() {
            return {
                iconUrlZoomExtent:IconZoomExtent,
            }
        },
        computed:{
            ...mapState(['map'])
        },
        watch: {
            // Observador para 'map'
            map(newMap) {
                if (newMap) {
                    this.initializeControl(newMap);
                }
            }
        },
        methods: {
            ...mapMutations(['addControl']),
            initializeControl(map){
                const zoomExtent = L.easyButton({
                position: 'bottomleft',
                states: [{
                    stateName: 'zoom-to-extent',        // name the state
                    icon:      `<img src="${IconZoomExtent}" style="width: 1.8em; height: 1.8em;">`,               // and define its properties
                    title:     'zoom to extent',      // like its title
                    onClick: function(btn, map) {       // and its callback
                        map.setView([4.2, -76.8],9);
                    }
                }]
            });
                zoomExtent.addTo(map);
                const diccionario = {
                    nameControl: 'zoomExtent',
                    control: zoomExtent
                };
                this.addControl(diccionario);
            }
        },
    }
</script>

<style scoped>
    @import url('~@/assets/plugins/leaflet_EasyButton/easy-button.css');
</style>