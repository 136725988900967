<template>
    <div>
        <div class='d-flex'>
            <!-- Boton para encender y apgar layer -->
            <!-- Div para el switch -->
            <div class="form-check form-switch flex-grow-1">
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="flexSwitchCheckChecked" 
                    v-model='encender'
                    @change="encenderApagarLayer"
                    checked>
                <label class="form-check-label text-capitalize" for="flexSwitchCheckChecked">{{item.nombre}}</label>
            </div>
            <!-- Div para la opacidad -->
            <div class="w-25" >
            <input 
                type="range" 
                class="form-range" 
                value='18' 
                id="customRange1" 
                min="0" 
                max="1" 
                step="0.1"
                v-model="opacity"
                @change="cambiarOpacidad">
            </div>
        </div>
        <!-- FIN LAYERS TERRENOS -->
        <!-- <v-divider></v-divider> -->
    </div>
</template>

<script>
    
    import {mapState} from 'vuex'
    export default {
        name:'AccordionLayer',
        props:['item'],
        computed:{
            ...mapState(['map']),
            
        },
        data() {
            return {
                array_fotos: ['Cartago', 'Guacari', 'Dagua11','Dagua12','Dagua13','Dagua14','Dagua21','Dagua22','Dagua23','Dagua24','Dagua31','Dagua32','Dagua33','Dagua34', 'Malambo','Lacumbre22','Lacumbre23','Lacumbre32','Lacumbre33','Lacumbre34','Zonas_Fisicas','Zonas_Geoeconomicas'],
                encender: false,
                opacity:0.8
            }
        },
        watch: {
            // Observa cambios en `item.nombre` para actualizar `encender`
            'item.nombre': {
                immediate: true, // Ejecuta el handler inmediatamente con el valor actual de la propiedad
                handler(newValue) {
                    //console.log(newValue,'nombrelayer')
                    this.encender = !this.array_fotos.includes(newValue);
                }
            }
        },
        methods: {
            encenderApagarLayer(){
                this.encender ? this.map.addLayer(this.item.layer) 
                              : this.map.removeLayer(this.item.layer)
            },
            cambiarOpacidad(){
                this.item.layer.setOpacity(this.opacity)
            }
        },
    }
</script>