<template>
<div>
  <div class="d-flex mb-2 justify-content-between">
    <div class="w-50">
      <v-text-field 
        v-model="npn" 
        @input="getSearchPredio" 
        hide-details placeholder="Consulta por npn"
        density="compact"
        variant="underlined"
        append-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <div class="text-center">
      <MenuDescarga :seleccionRows='selected'/>
    </div>
  </div>
  <v-data-table-server
    :items-per-page="itemsPerPage"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    :search="search"
    :footer-props="{
      'items-per-page-text': 'Elementos por página:'
    }"
    item-key="id"
    @update:options="loadItems"
  >
    <template v-slot:item="{ item }">
      <tr :class="{ 'bg-azul-claro': item.terreno_geo, 'text-center': true }">
        <td>
          <input
            type="checkbox"
            :value="isSelected(item)"
            @change="toggleSelection(item)"
          >
        </td>
        <td>{{ item.npn }}</td>
        <td>{{ item.destino }}</td>
        <td>{{ item.area_terreno_digitada }}</td>
        <td>{{ item.area_construida_digitada }}</td>
        <td>
          <v-btn
            small
            :disabled= '!item.terreno_geo'
            :append-icon="item.terreno_geo ? 'mdi-eye' : 'mdi-eye-off'"
            @click="onButtonClick(item)"
          >
            Ver mapa
            <template v-slot:append>
              <v-icon :color="item.terreno_geo?'warning':'grey'"></v-icon>
            </template>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table-server>
</div>
</template>
<script>
  import axios from '@/services/axios/axios';
  import MenuDescarga from '@/components/map/sidebar/tabla/MenuDescarga.vue'

  import { mapState } from 'vuex';
  export default {
    name:'DataTable',
    components:{
      MenuDescarga
    },
    props:['prediosTableChild', 'urlDepMun'],
    data: () => ({
      selected:[],
      itemsPerPage: 10,
      headers: [
        {
          title: 'Seleccion',
          align: 'center',
        },
        {
          title: 'Numero predial nacional',
          align: 'center',
          sortable: false,
          key: 'npn',
        },
        { title: 'Destinacion', key: 'destino', align: 'center' },
        { title: 'Area terreno', key: 'area_terreno_digitada', align: 'center' },
        { title: 'Area construida', key: 'area_construida_digitada', align: 'center' },
        { text: 'Actions', value: 'action' }
      ],
      search: '',
      desserts:[],
      serverItems: [],
      loading: true,
      totalItems: 0,
      npn:'',
      initialDataLoaded: true,
      url:''
    }),
    watch:{
      prediosTableChild(newValue){
        this.serverItems = newValue.results
        this.totalItems = newValue.count
      },
      urlDepMun(newValue){
        this.url = newValue
      }
    },
    computed:{
        ...mapState(['map'])
    },
    methods: {
      isSelected(item) {
        return this.selected.includes(item); // Asumiendo que 'id' es tu item-key
      },
      toggleSelection(item) {
        const index = this.selected.indexOf(item);
        if (index > -1) {
          this.selected.splice(index, 1); // Des-seleccionar
        } else {
          this.selected.push(item); // Seleccionar
        }
      },
      onButtonClick(item) {
        // Lógica al hacer clic en el botón
        if (!item?.terreno_geo) return null;
        const consultaJson = L.geoJSON(item?.terreno_geo).addTo(this.map)
        this.map.fitBounds(consultaJson.getBounds())
      },
      eraseItems(){
        this.serverItems = []
      },
      compareNumbers(longNumber, variable) {
        const variableLength = variable.length;
        const comparisonString = longNumber.substring(0, variableLength);
        if (comparisonString === variable) {
          return true;
        }
        return null;
      },
      extractIdFromUrl(urlString) {
        //const url = new URL(urlString);
        const pathSegments = urlString.split('/'); // Divide la URL en segmentos basados en '/'
        
        // Extrae los segmentos relevantes
        let departmentId = pathSegments[pathSegments.length - 1];
        let optionalId = pathSegments[pathSegments.length - 2];

        // Verifica si el último segmento es un número
        if (!isNaN(departmentId)) {
          // Si el último segmento es un número, comprueba si el penúltimo segmento también lo es
          if (!isNaN(optionalId)) {
            return optionalId + departmentId; // Retorna ambos números concatenados
          }
          return departmentId; // Retorna solo el último número
        }
        return null; // Retorna null si el último segmento no es un número
      },
      async getSearchPredio () {
        this.loading = true;
        const npn = this.npn;
        if (!npn) {
            this.loading = false;
            return;
        }
        const depMunicipio = this.extractIdFromUrl(this.urlDepMun);
        const validacionNpn = this.compareNumbers(npn, depMunicipio);

        if (!validacionNpn){
          this.loading = false;
          return null
        };

        try {
            const response = await axios.get(`geovisor/list_predio_npn/${npn}`);
            this.serverItems = response.data.results;
            this.totalItems = response.data.results.length;
        } catch (error) {
            console.error('Error al obtener información:', error);
            // Manejar el error adecuadamente aquí
        } finally {
            this.loading = false;
        }
      },
      async loadItems ({ page, itemsPerPage}) {
          this.loading = true;
          this.serverItems = this.prediosTableChild.results;
          this.totalItems = this.prediosTableChild.results.length;
          const url = this.urlDepMun;

          try {
              const response = await axios.get(`${url}`, {
                  params: {
                      page: page,
                      page_size: itemsPerPage
                  }
              });

              this.serverItems = response.data.results; // los datos para la tabla
              this.totalItems = response.data.count; // total de elementos para la paginación
          } catch (error) {
              console.error('Error al cargar datos:', error);
          } finally {
              this.loading = false;
          }
      }
    },
  }
</script>

<style scoped>
  .bg-azul-claro {
    background-color: rgba(81, 191, 241, 0.3); /* Color verde claro */
  }

</style>