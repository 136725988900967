import { createRouter, createWebHistory } from 'vue-router';
import { authService } from '@/services/axios/authService'
import Login from '@/components/login/Login.vue';
import ResetPassword from '@/components/login/ResetPassword.vue';
import ActivateAccount from '@/components/login/ActivateAccount.vue'
import Map from '@/components/map/Map.vue';

const routes = [
    {
        path: '/',
        redirect: '/login' // Redirige de '/' a '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/map',
        name: 'map',
        component: Map,
        meta: { requiresAuth: true } // Agrega un meta campo para identificar rutas protegidas
    },
    {
        path: '/reset-password/:uidb64/:token',
        name: 'reset-password',
        component: ResetPassword
    },
    {
        path: '/activate-account/:uidb64/:token',
        name: 'activate-account',
        component: ActivateAccount
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // Verifica si la ruta requiere autenticación
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Aquí puedes añadir una lógica para verificar si el usuario está autenticado
        // Por ejemplo, hacer una solicitud a un endpoint que verifique la sesión
        // Si no está autenticado, redirige al login
        // Verifica si el usuario está autenticado
        const isAuthenticated = await authService.checkAuth();

        if (isAuthenticated) {
            next(); // Si está autenticado, permite acceder a la ruta
        } else {
            next('/login'); // Si no está autenticado, redirige a la página de inicio de sesión
        }
    } else {
        next(); // No requiere autenticación, permitir acceso
    }
});

export default router;