<template>
    <div style="width:100" class="m-2">
        <p class="t-6 fs-6 fw-bold">Localizacion por coordenas</p>
        <v-combobox
            :items="itemsComboBoxLocalizacion"
            label="Seleccione el sistema de proyecccion"
            outlined
            filled
            dense
            solo
            disabled
            >
        </v-combobox>
        <div>
            <v-text-field
                label="Latitud"
                type='number'
                density="compact"
                hide-details="auto"
                v-model='latitud'
                :rules="rulesLocalizacionLatitud"
            ></v-text-field>
            <v-text-field
                label="Longitud"
                type='number'
                density="compact"
                hide-details="auto"
                class="my-4"
                v-model='longitud'
                :rules="rulesLocalizacionLongitud"
            ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            block
            @click="locationCoord"
        >
            Enviar
        </v-btn>
    </div>
</template>
 
<script>
    import { mapState } from 'vuex'
    import iconPin from '@/assets/customIcons/pin.png'

    export default {
        name:'LocationCoord',
        computed:{
            ...mapState(['map'])
        },
        data() {
            return {
                iconUrlPin : iconPin,
                latitud:null,
                longitud:null,
                markerLocation:null,
                itemsComboBoxLocalizacion: ['WGS84: Geograficas', 'CMT12: Origen nacional'],
                rulesLocalizacionLatitud: [
                    value => !!value || 'Valor requerido.',
                    value => (value && value >= 3.08 && value <= 5.04 ) || 'La latitud debe estar entre 3.08 y 5.04 grados decimales',
                ],
                rulesLocalizacionLongitud: [
                    value => !!value || 'Valor requerido.',
                    value => (value && value >= -77.6 && value <= -75.69 ) || 'La latitud debe estar entre -77.6 y-75.69 grados decimales',
                ],
            }
        },
        methods:{
            locationCoord(){
                if ((this.longitud && this.longitud >= -77.6 && this.longitud <= -75.69) && (this.latitud && this.latitud >= 3.08 && this.latitud <= 5.04 )){
                    this.markerLocation = L.marker([this.latitud,this.longitud], {
                        icon:this.customIconMarker(this.iconUrlPin)
                        }).addTo(this.map)
                    this.map.flyTo([this.latitud,this.longitud])
                }else{
                    alert('Por favor completa el formulario')
                }
            },
            customIconMarker(url){ 
                return L.icon({
                    iconUrl: url,
                    iconSize: [30, 30],               // Tamaño más pequeño del ícono
                    iconAnchor: [15, 30],              // Ajusta el punto del ícono que corresponderá a la ubicación del marcador
                    popupAnchor: [-3, -30]  
                })
            }   
        },
    }
</script>