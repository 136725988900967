import { createStore } from 'vuex'

export default createStore({
  state: {
    map: undefined,
    layers:[],
    controls:[],
    predioResult:undefined,
    //DATA FOR TABLES
    urlPagination:'',
    // LOGIN
    isAuthenticated: false,

  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.isAuthenticated = value;
    },
    addLayer(state, item){
      state.layers.push(item);
    },
    addPredioResult(state, predio){
      state.predioResult = predio
    },
    addControl(state,item){
      state.controls.push(item);
    },
    updateMap(state, newMap) {
      state.map = newMap;
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.accessToken;
    },
  },
  actions: {
    setAuthenticated({ commit }, value) {
      commit('SET_AUTHENTICATED', value);
    },
    addLayerAsync({commit, state}, diccionario){
      if(!diccionario.style){
        diccionario.style = ()=>{
            return {
                color: 'orange',  // Color del contorno
                weight: 2,        // Grosor del contorno
                opacity: 1,       // Opacidad del contorno
                fillColor: 'transparent', // Color del relleno
                fillOpacity: 0    // Opacidad del relleno
            }
        }
      }
      const terreno = diccionario.data
      const layer={
        nombre: diccionario.nameLayer,
        layer : L.geoJson(terreno,{style:diccionario.style})
      }
      layer.layer.addTo(state.map)
      state.map.flyToBounds(layer.layer.getBounds())
      commit('addLayer', layer)
    }
  },
  modules: {
  }
})
