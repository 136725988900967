<template>
    <div>
        <transition name="fade">
            <div ></div>
        </transition>
        <div class="wallpaper-register"></div>
        <div class="container">
            <div class="row">
                <div v-if='isTokenValid' class="col-lg-4 col-md-6 col-sm-8 mx-auto">
                    <div class="card login" v-bind:class="{ error: emptyFields }">
                        <div class="d-flex justify-content-around">
                            <img :src="iconoUrlCheck" alt="" class="me-auto p-2" width="60" height="60">
                            <p class="custom-text ml-1 p-2 text-justify"> Se ha activado tu cuenta satisfactoriamente, ya puedes iniciar sesión. <a href="/login">Iniciar sesión aquí.</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { authService } from '@/services/axios/authService';
    //ICONS
    import checkmark from '@/assets/customIcons/check-mark.png'; // Asegúrate de que la ruta sea correcta

    export default {
        name:'ActivateAccount',
        data() {
            return {
                // icon
                isTokenValid: false,  // Agregar una propiedad para controlar la validez del token
                tokenExpirationTimer: null,
                iconoUrlCheck: checkmark,
                uidb64: '',
                token:'',
            };
        },
        mounted() {
            this.uidb64 = this.$route.params.uidb64;
            this.token = this.$route.params.token;
            this.activateUser(this.uidb64, this.token);
        },
        methods: {
            async activateUser(uidb64, token){
                const data  = await authService.activateAccount(uidb64, token);
                if (data == 'ok') {
                    this.isTokenValid = true; // Establecer el temporizador si el token es válido
                }else{
                    this.$router.push('/login'); // Redirigir si el token no es válido
                }
            },
            async verifyToken(uidb64, token) {
                const esTokenValido  = await authService.validarToken(uidb64, token);
                this.isTokenValid = esTokenValido;
                if (this.isTokenValid) {
                    this.setTokenExpirationTimer(); // Establecer el temporizador si el token es válido
                } else {
                    this.$router.push('/login'); // Redirigir si el token no es válido
                }
            },
            setTokenExpirationTimer() {
                const expirationDuration = 2 * 60 * 1000;  // Duración hasta la expiración del token (por ejemplo, 10 minutos)
                this.tokenExpirationTimer = setTimeout(() => {
                    this.verifyTokenAgain();
                }, expirationDuration);
            },
            async verifyTokenAgain() {
                const esTokenValido  = await authService.validarToken(this.uidb64, this.token);
                if (!esTokenValido) return  this.$router.push('/login');
            },
        },
        beforeDestroy() {
            if (this.tokenExpirationTimer) {
                clearTimeout(this.tokenExpirationTimer);  // Limpiar el temporizador si el componente se destruye
            }
        }
    };
</script>

<style scoped>
    .custom-text{
        font-size: 0.8rem;
    }
    p {
        line-height: 1rem;
    }

    .card {
        padding: 20px;
    }

    .login-page {
        align-items: center;
        display: flex;
        height: 100vh;
    }

    .wallpaper-login {
        background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    
    .wallpaper-register {
        background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
            no-repeat center center;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    h1 {
        margin-bottom: 1.5rem;
    }
  

    .error {
        animation-name: errorShake;
        animation-duration: 0.3s;
    }

    @keyframes errorShake {
        0% {
            transform: translateX(-25px);
        }
        25% {
            transform: translateX(25px);
        }
        50% {
            transform: translateX(-25px);
        }
        75% {
            transform: translateX(25px);
        }
        100% {
            transform: translateX(0);
        }
    }
</style>