<template>
  <transition name="modal">
    <div class="modal-mask" @click.self="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container">
          
          <div class="modal-body">
            <slot name="body">
              <video ref="videoPlayer" width="100%" controls>
                <source src="@/assets/video/VideoTutorial.mp4" type="video/mp4">
              </video>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">

              <a href="/MANUAL_USUARIO_Valle Avanza S.A.S 2023-2_geoportal.pdf"  target="_blank" class="modal-link-button">
                <v-btn 
              size="small"
              color="purple"
              >Manual Usuario</v-btn>
            </a>
              <v-btn size="small" 
              color="blue-darken-4"              
              @click="cerrarModal">Cerrar</v-btn>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VideoTutorial',
  props:['showModal'],
  methods: {
    cerrarModal() {
      //console.log(this.showModal, 'boton funcionando')
      this.$emit('close', false); // Emitir evento de cierre del modal
    }
  },
  watch: {
    // Observa cambios en la propiedad 'miVariable'
    miVariable(newVal, oldVal) {
      console.log('miVariable ha cambiado de', oldVal, 'a', newVal);
      // Aquí puedes añadir la lógica que necesitas ejecutar cuando miVariable cambia
    }
  }
}
</script>

  
  <style scoped>
  /* Estilos CSS para el modal */
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    max-width: 65%;
    overflow: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }
  .modal-header, .modal-footer {
    padding: 20px 0;
    margin:10px;
  }
  .modal-default-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .modal-default-button:hover {
    background-color: #0056b3;
  }
  .modal-body {
  padding: 20px; /* Ajusta el valor del margen según sea necesario */
  }

  </style>
  