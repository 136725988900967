<template>
    <div style="width:100" class="containerExchangeNpn m-2">
        <v-responsive
        >
            <p class="t-6 fs-6 fw-bold">Cambio de numero predial</p>
            <p>Digita el numero de 30 posiciones (Npn) o el de 20 (Numero predial) y transforma el numero.</p>
            <v-text-field
                :rules="[rules.required, rules.npn]"
                v-model="npn"
                clearable
                density="compact"
                label="Identificador catastral"
            ></v-text-field>
            <v-btn 
                block 
                color="green"
                @click="convertirNumeroPredial"
            >Convertir</v-btn>
            <div v-if='numeroPredialFinal' class="mt-3">
                <div class="text-center">
                    <span class="fw-bold"> El numero convertido es: </span>
                    <p class="fst-italic">{{numeroPredialFinal}}</p>
                </div>
                <p>
                    <span class="fw-bold">Nota:</span> Este proceso no funcionara para las propiedades horizontales,
                    ya que el numero predial de 20 posiciones no contempla pisos o torres.
                </p>
                
            </div>
            
        </v-responsive>
    </div>
</template>

<script>
export default {
    name:'ExchangeNpn',
    data: () => ({
        disableButton: true,
        numeroPredialFinal:null,
        npn: undefined,
        rules: {
            required: value => !!value || 'El campo es requerido.',
            npn: function(value){
                const regex = /^\d{20}(\d{10})?$/;
                const validacion = regex.test(value)
                return validacion || 'El numero debe ser de 20 o 30 posiciones y no puede contener letras';
            }
        },
    }),
    methods: {
        convertTo20(numeroPredial){
            let numeroPredialFinal = null;
            const condicion = numeroPredial[21];
            const depaMuni = numeroPredial.slice(0,9);
            const manzana = numeroPredial.slice(13,17);
            let terreno =null;
            const predio = numeroPredial.slice(26,30);
            if (condicion == '8' || condicion == '9'){
                terreno = numeroPredial.slice(18,21);
                numeroPredialFinal = `${depaMuni}${manzana}${predio}${terreno}`
            }else{
                terreno = numeroPredial.slice(17,21);
                numeroPredialFinal = `${depaMuni}${manzana}${terreno}000`
            }
            this.numeroPredialFinal = numeroPredialFinal
        },
        convertTo30(numeroPredial){
            let numeroPredialFinal = null;
            const predioCondicion = parseInt(numeroPredial.slice(17,20));
            const predio = numeroPredial.slice(17,20);
            const depaMuni = numeroPredial.slice(0,9);
            const manzana = numeroPredial.slice(9,13);
            let terreno=numeroPredial.slice(13,17);
            let condicion = null;
            if (predioCondicion > 800){
                if (predioCondicion > 800 && predioCondicion < 900){
                    condicion = '8'
                }else{
                    condicion = '9'
                }
                numeroPredialFinal = `${depaMuni}0000${manzana}0${predio}${condicion}0000${terreno}`
            }else if (predioCondicion > 0 && predioCondicion < 800 ){
                numeroPredialFinal = `${depaMuni}0000${manzana}${terreno}500000${predio}`
            }else{
                numeroPredialFinal = `${depaMuni}0000${manzana}${terreno}000000${predio}`
            }
            this.numeroPredialFinal = numeroPredialFinal
        },
        convertirNumeroPredial(){
            const numeroPredial = String(this.npn);
            return  numeroPredial.length == 20 ? this.convertTo30(numeroPredial) :
                    numeroPredial.length == 30 ? this.convertTo20(numeroPredial) :
                    'El numero no es valido'
        }

    },
}
</script>